import Clear from '@/layouts/Clear.vue';

export default {
  path: '/admins',
  component: Clear,
  children: [
    {
      path: '',
      name: 'admins.index',
      component: () => import('@/views/Admin/AdminList.vue'),
    },
    {
      path: ':id?',
      name: 'admins.save',
      component: () => import('@/views/Admin/Admin.vue'),
    },
  ],
};
