import axios from 'axios';
import store from '../store';
import router from '../router';
import { SnackbarProgrammatic as Snackbar } from 'buefy';

import { AUTH_LOGOUT } from '../store/actions/auth';

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

api.interceptors.response.use(
  (request) => request,
  (error) => {
    const { response, config } = error;

    if (!config.url.includes('login') && response.status === 401) {
      snackbarSession();
      store.dispatch(AUTH_LOGOUT);
      router.push('/login');
    }

    return Promise.reject(error);
  }
);

const snackbarSession = () => {
  Snackbar.open('Sua sessão expirou. Realize login novamente para continuar.');
};

export default api;
