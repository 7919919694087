import Vue from 'vue';

export default Vue.filter('currency', (value) => {
  if (value === null || value === undefined || value === '') {
    return '';
  }

  return parseFloat(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
});
