export default [
  {
    icon: 'home',
    label: 'Dashboard',
    routeName: 'dashboard',
  },
  {
    icon: 'calendar-alt',
    label: 'Consultas',
    routeName: 'schedules.index',
  },
  {
    icon: 'user-cog',
    label: 'Administradores',
    routeName: 'admins.index',
  },
  {
    icon: 'user-md',
    label: 'Médicos',
    routeName: 'doctors.index',
  },
  {
    icon: 'user-injured',
    label: 'Pacientes',
    routeName: 'patients.index',
  },
  {
    icon: 'stethoscope',
    label: 'Especialidades',
    routeName: 'specialties.index',
  },
  {
    icon: 'heartbeat',
    label: 'Subespecialidades',
    routeName: 'subspecialties.index',
  },
  {
    icon: 'passport',
    label: 'Termos de uso',
    routeName: 'terms.index',
  },
  {
    icon: 'cogs',
    label: 'Configurações',
    routeName: 'settings.index',
  },
  // {
  //   icon: 'mobile-alt',
  //   label: 'Configurações do Aplicativo',
  //   routeName: 'app-setting.index',
  // },
];
