<template>
  <div class="no-content">
    <b-icon :icon="icon" :pack="pack"></b-icon>
    <p>{{ text }}</p>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    icon: {
      type: String,
      default: 'frown',
    },
    pack: {
      type: String,
      default: 'fas',
    },
  },
};
</script>

<style lang="scss" scoped>
.no-content {
  text-align: center;

  .icon {
    font-size: 2.5rem;
    color: var(--text-color-light);
  }

  p {
    letter-spacing: 1px;
    color: var(--text-color-medium);
  }
}
</style>
