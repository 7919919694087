<template>
  <div id="app">
    <loading-page v-if="isLoading"></loading-page>
    <router-view v-if="!isLoading" />
  </div>
</template>

<script>
import LoadingPage from '@/views/Loading.vue';
import { AUTH_TOKEN } from '@/store/actions/auth';

export default {
  components: {
    LoadingPage,
  },
  computed: {
    isLoading() {
      return this.$store.state.auth.loading;
    },
  },
  mounted() {
    this.$store
      .dispatch(AUTH_TOKEN)
      .then(() => this.$router.push('/dashboard'))
      .catch(() => {
        if (this.$route.name !== 'login') this.$router.push('/login');
      });
  },
};
</script>

<style lang="scss">
@import 'styles/_global.scss';

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
}
</style>
