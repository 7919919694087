<template>
  <div class="login-view">
    <div class="info">
      <div>
        <div class="text">
          <p>Bem-vindo à área <br />de login administrativo</p>
        </div>
      </div>
    </div>
    <div class="form">
      <div class="fields">
        <form @submit.prevent="login">
          <div class="form-fields">
            <div class="header" v-if="!isPasswordReset">
              <h1>Login</h1>
              <p>Entre com e-mail e senha</p>
            </div>
            <div class="header reset-info" v-if="isPasswordReset">
              <h1>Esqueci minha senha</h1>
              <p>
                Será enviado um e-mail para o endereço inserido com os próximos
                passos
              </p>
            </div>
            <b-field>
              <b-input
                v-model="email"
                custom-class="center-text"
                placeholder="E-mail"
                type="email"
                autocomplete="email"
              ></b-input>
            </b-field>
            <b-field v-if="!isPasswordReset">
              <b-input
                v-model="password"
                custom-class="center-text"
                placeholder="Senha"
                type="password"
              ></b-input>
            </b-field>
          </div>
          <div class="action-buttons" v-if="!isPasswordReset">
            <b-button
              :loading="isLoading"
              :disabled="isLoading"
              native-type="submit"
              type="is-primary"
              class="mt-5 login-button"
              expanded
              rounded
            >
              Entrar
            </b-button>
            <b-button
              @click="isPasswordReset = !isPasswordReset"
              type="is-text"
              class="mt-2 password-recovery"
              expanded
            >
              Esqueceu sua senha? Clique aqui
            </b-button>
          </div>
          <div class="action-buttons reset-button" v-if="isPasswordReset">
            <b-button
              @click="requestPasswordReset"
              :loading="isLoading"
              :disabled="isLoading"
              native-type="submit"
              type="is-primary"
              class="mt-5"
              expanded
              rounded
            >
              Enviar
            </b-button>
            <b-button
              @click="isPasswordReset = !isPasswordReset"
              type="is-text"
              class="mt-2 go-to-login"
              expanded
            >
              Fazer Login
            </b-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { AUTH_REQUEST } from '@/store/actions/auth';
import UserService from '@/services/user.service';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    email: null,
    password: null,
    isLoading: false,
    isPasswordReset: false,
  }),
  computed: {
    ...mapGetters(['readOnly', 'secondStage']),
  },
  methods: {
    login() {
      const { email, password } = this;
      this.isLoading = true;

      this.$store
        .dispatch(AUTH_REQUEST, { email, password })
        .then(() => {
          this.$router.push('dashboard/');
        })
        .catch(() => {
          this.isLoading = false;
          this.$buefy.snackbar.open('Dados inválidos. Tente novamente.');
        });
    },
    requestPasswordReset() {
      const { email } = this;
      this.isLoading = true;

      UserService.requestPasswordReset(email)
        .then(({ data }) => {
          this.$buefy.snackbar.open(data.message);
          this.isPasswordReset = !this.isPasswordReset;
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.login-view {
  background: #fff;
  height: 100vh;

  display: flex;
  justify-content: space-between;
  overflow: hidden;

  .info {
    width: 70%;
    height: 100vh;
    padding-left: 50px;

    background: url('~@/assets/img/bg3.jpg');
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    color: #fff;
    font-family: 'Poppins';

    div {
      position: relative;
      top: 28vh;

      .text {
        width: 100%;

        font-size: 2.3rem;
        color: #fff;
      }
    }
  }

  .form {
    width: 30%;
    height: 100vh;

    background: url('~@/assets/img/detalhe.png');
    background-color: #fff;
    background-position: 10vw 105%;
    background-repeat: no-repeat;
    background-size: contain;

    padding: 3%;

    .fields {
      height: 100%;
      position: relative;
      top: 5vh;

      font-family: 'Poppins';

      .header {
        margin-bottom: 30px;
        text-align: center;
        color: #47a5d7;

        h1 {
          font-size: 1.6rem;
          font-weight: 700;
          margin-bottom: 10px;
        }
        p {
          font-style: italic;
          font-family: serif;
          font-size: 1.3rem;
        }
      }

      .reset-info {
        text-align: left;
        color: gray;
        p {
          font-size: 1rem;
          font-style: normal;
        }
      }

      .action-buttons {
        position: relative;

        .password-recovery,
        .go-to-login {
          color: #47a5d7;
          font-size: 0.9rem;
          text-decoration: none;
        }

        .password-recovery:focus {
          outline: none;
        }
      }

      .action-buttons.reset-button {
        top: 0;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .login-view {
    .info {
      div {
        .text {
          font-size: 1.7rem;
        }
      }
    }
    .form {
      .fields {
        .action-buttons {
          top: 40vh;
        }
      }
    }
  }
}

@media only screen and (max-width: 947px) {
  .login-view {
    .info {
      div {
        .text {
          font-size: 1.2rem;
        }
      }
    }
  }
}
</style>
