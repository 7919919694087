import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import DashboardLayout from '@/layouts/Dashboard.vue';
import Login from '@/views/Login.vue';
import Home from '@/views/Home.vue';
import ADMIN_ROUTES from '@/router/admin.routes';
import DOCTOR_ROUTES from '@/router/doctor.routes';
import PATIENT_ROUTES from '@/router/patient.routes';
import SPECIALTY_ROUTES from '@/router/specialty.routes';
import SUBSPECIALTY_ROUTES from '@/router/subspecialty.routes';
import SCHEDULE_ROUTES from '@/router/schedule.routes';
import SETTINGS_ROUTES from '@/router/settings.routes';
import TERM_ROUTES from '@/router/term.routes';
import DASHBOARD_ROUTES from '@/router/dashboard.routes';
import APP_SETTING_ROUTES from '@/router/app-setting.routes';

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/login');
};

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/',
    component: DashboardLayout,
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: '/home',
        name: 'index',
        component: Home,
      },
      ADMIN_ROUTES,
      DOCTOR_ROUTES,
      PATIENT_ROUTES,
      SPECIALTY_ROUTES,
      SUBSPECIALTY_ROUTES,
      SCHEDULE_ROUTES,
      SETTINGS_ROUTES,
      TERM_ROUTES,
      DASHBOARD_ROUTES,
      APP_SETTING_ROUTES
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
