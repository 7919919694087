import Vue from 'vue';

import DateFilter from '../filters/DateFilter';
import NumberFilter from '../filters/NumberFilter';
import CurrencyFilter from '../filters/CurrencyFilter';
import TitleCaseFilter from '../filters/TitleCaseFilter';
import MaskFilter from '../filters/MaskFilter';

Vue.use('date', DateFilter);
Vue.use('number', NumberFilter);
Vue.use('currency', CurrencyFilter);
Vue.use('titleCase', TitleCaseFilter);
Vue.use('mask', MaskFilter);
