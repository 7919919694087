import Vue from 'vue';

import AppInput from '@/components/inputs/AppInput.vue';
import AppHeader from '@/components/AppHeader.vue';
import NoContent from '@/components/NoContent.vue';
import AppFilePicker from '@/components/inputs/AppFilePicker.vue';
import { ValidationProvider } from 'vee-validate';

Vue.component('app-input', AppInput);
Vue.component('app-header', AppHeader);
Vue.component('no-content', NoContent);
Vue.component('app-file-picker', AppFilePicker);
Vue.component('ValidationProvider', ValidationProvider);
