<template>
  <div class="loading-view">
    <div class="progress-container">
      <b-progress type="is-primary" size="is-small"></b-progress>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
div.loading-view {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .progress-container {
    width: 200px;
  }
}

@keyframes zooming {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
</style>
