import Clear from '@/layouts/Clear.vue';

export default {
  path: '/patients',
  component: Clear,
  children: [
    {
      path: '',
      name: 'patients.index',
      component: () => import('@/views/Patient/PatientList.vue'),
    },
    {
      path: ':id?',
      name: 'patients.save',
      component: () => import('@/views/Patient/Patient.vue'),
    },
  ],
};
