import Clear from '@/layouts/Clear.vue';

export default {
  path: '/app-setting',
  component: Clear,
  children: [
    {
      path: '',
      name: 'app-setting.index',
      component: () => import('@/views/AppSetting/AppSetting.vue'),
    },
  ],
};