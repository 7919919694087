import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Tooltip from 'vue-directive-tooltip';

import './config/AppComponentsConfig';
import './config/AppDirectivesConfig';
import './config/AppFiltersConfig';
import './config/FontAwesomeConfig';
import './config/MomentConfig';
import './config/BuefyConfig';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
import VueMask from 'v-mask'

import Vuelidate from 'vuelidate'

Vue.use(Tooltip);
Vue.use(require('vue-moment'));
Vue.use(VueMask);
Vue.use(Vuelidate)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
