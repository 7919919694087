import Clear from '@/layouts/Clear.vue';

export default {
  path: '/doctors',
  component: Clear,
  children: [
    {
      path: '',
      name: 'doctors.index',
      component: () => import('@/views/Doctor/DoctorList.vue'),
    },
    {
      path: ':id?',
      name: 'doctors.save',
      component: () => import('@/views/Doctor/Doctor.vue'),
    },
  ],
};
